// This component has been migrated to V3
// If you make changes to this component, please drop a note in #lp-instui-react-upgrade

import React from 'react';
import cn from 'classnames';
import MUIModal, { ModalProps } from '@material-ui/core/Modal';
import { capitalize } from 'utils/strings';
import Button from 'components/Button';
import IconButton from 'components/IconButton';
import useStyles from './useStyles';

interface Props extends ModalProps {
  title?: string;
  titleClassName?: string;
  button?: boolean;
  buttonText?: string;
  buttonAlign?: 'left' | 'center' | 'right';
  headerTabContent?: string;
  customStyle?: string;
  noPadding?: boolean;
  headerButtons?: JSX.Element;
  onClose(): void;
}

const Modal = ({
  children,
  title = '',
  titleClassName,
  onClose,
  button = true,
  buttonText = 'Ok',
  buttonAlign = 'right',
  open,
  headerTabContent,
  customStyle,
  noPadding,
  headerButtons,
  ...rest
}: Props) => {
  const classes = useStyles(rest);
  const actionsStyles = cn(classes.actions, [classes[`actionsAlign${capitalize(buttonAlign)}`]]);

  return (
    <MUIModal onClose={onClose} open={open} {...rest}>
      <div data-node="modal_window" className={cn(classes.paper, customStyle)}>
        {title ? (
          <div className={cn(classes.title, titleClassName)}>
            <div data-node="modal-title" className={classes.titleText}>
              {title}
            </div>
            <div data-node="modal_header_buttons" className={classes.headerButtons}>
              {headerButtons}
              <IconButton
                data-node="close_icon"
                className={classes.icon}
                icon="close"
                size="small"
                edge="end"
                onClick={onClose}
                aria-label="close modal"
              />
            </div>
          </div>
        ) : (
          <div className={classes.nontitle}>
            <div data-node="modal_header_buttons" className={classes.headerButtons}>
              {headerButtons}
              <IconButton
                data-node="close_icon"
                className={classes.icon}
                icon="close"
                onClick={onClose}
                aria-label="close modal"
              />
            </div>
          </div>
        )}
        {headerTabContent}
        <div
          className={customStyle ? classes.customContentStyle : cn(classes.content, { [classes.noPadding]: noPadding })}
        >
          {children}
        </div>
        {button && (
          <div className={actionsStyles}>
            <Button
              onClick={onClose}
              // @ts-ignore
              id="modal-close-button"
              data-node="ok_button"
            >
              {buttonText}
            </Button>
          </div>
        )}
      </div>
    </MUIModal>
  );
};

// Can disable the rule because we're passing ...rest to useStyles()
// eslint-disable-next-line lt-custom/safe-classes-prop
export default Modal;
